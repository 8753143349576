import { createApp } from "vue";
import { antArr } from "./lib/AntDesignVue";
import * as Icons from "@ant-design/icons-vue"; //图标使用
import lazy from './directives/lazy'
import lazyload from './directives/lazyload'
import App from "./App.vue";
import pinia from "./store";
const app = createApp(App);
app.directive('lazy', lazy)
app.directive('lazyload', lazyload)

import Axios from "./request/https";
import router from "./router";

for (let item of antArr) {
    app.use(item);
}

for (const i in Icons) {
    //图标使用
    app.component(i, Icons[i]);
}






// 自定义图片懒加载

const VUE_APP_VERSION = require('../package.json').version
const vers = window.localStorage.getItem("Version") || '';
if (VUE_APP_VERSION !== vers) {
    console.log('版本号不一致', VUE_APP_VERSION, vers);

    localStorage.clear()
    window.localStorage.setItem("Version", VUE_APP_VERSION);
    location.reload(true)
} else {
    console.log('版本号一致', VUE_APP_VERSION, vers);

}





app.use(pinia).use(Axios).use(router).mount("#app");
// const debounce = (fn, delay) => {
//     let timer;
//     return (...args) => {
//         if (timer) {
//             clearTimeout(timer);
//         }
//         timer = setTimeout(() => {
//             fn(...args);
//         }, delay);
//     };
// };
// const _ResizeObserver = window.ResizeObserver;
// window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
//     constructor(callback) {
//         callback = debounce(callback, 200);
//         super(callback);
//     }
// };

