import { Item } from "ant-design-vue/es/menu";
import axios from "axios";
axios.defaults.baseURL = process.env.VUE_APP_BASE_API;
import router from "./index"

let routes = [
    {
        path: "/",
        name: "Layout",
        redirect: "/Login",
        component: () => import("@/views/Layout/Layout.vue"),
        meta: {
            title: 'layout',
            icon: '',
            hidden: false,
            fatherPath: '/',

        },

        children: []
    }

]
const getbaseinfo = async () => {
    const result = await axios.get("/biz/WebSite/SystemDetail");
    console.log("我是router基本信息1111111", result);
    if (result.status == 200 && result.data.code == 200) {

        localStorage.setItem("baseinfo", JSON.stringify(result.data.data));
        let baseinfo = result.data.data;
        let menuTop = JSON.parse(baseinfo.menuTop)
        console.log('baseinfo', baseinfo);
        console.log('路由中menuTop', menuTop);

        menuTop.forEach(element => {
            console.log('element', element);

            let obj = {
                path: `/${element.path}`,
                name: element.path,
                component: element.path,
                meta: {
                    title: element.title,
                    icon: element.icon,
                    fatherPath: '/'
                },
                children: []
            }
            routes[0].children.push(obj)

            if (element.children && element.children.length) {
                element.children.forEach(Item => {
                    let obj1 = {
                        path: `/${Item.path}`,
                        name: Item.path,
                        component: Item.path,
                        meta: {
                            title: Item.title,
                            icon: Item.icon,
                            fatherPath: obj.path
                        },
                    }
                    obj.children.push(obj1)
                })

            }
        });


        formatRoutes(routes)
        getmenu(routes)
        return routes


    } else {
        console.log("error", result);
    }
};
export function getmenu(routes) {
    console.log('获取菜单routes', routes);

    if (routes) {
        menuRoutes = routes.find((r) => r.name === "Layout");
        console.log('menuRoutes', menuRoutes);
        localStorage.setItem('menuRoutes', JSON.stringify(menuRoutes))
        return menuRoutes;
    } else {
        return menuRoutes;
    }


}
// 动态添加路由
export function formatRoutes(routes) {
    let fmRoutes = [];

    routes.forEach((routers) => {
        let { path, component, name, meta, icon, children } = routers;
        // 递归
        if (children && children.length) {
            children = formatRoutes(children);
        }
        let fmRouter = {}
        fmRouter = {
            path: path,
            name: name,
            meta: meta,
            icon: icon,
            children: children,
            component: () => {
                return import("@/views/" + name + "/" + name + ".vue");
            }
        }
        fmRoutes.push(fmRouter);
        router.addRoute(fmRouter)
    })
    console.log('@@@@fmRoutes', fmRoutes);


}

export let menuRoutes = [];
export default getbaseinfo;