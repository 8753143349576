

let routes = [
    {
        path: "/",
        name: "Layout",
        redirect: "/Home",
        keywords: '',
        description: '',
        component: () => import("@/views/Layout/Layout.vue"),
        meta: {
            title: 'layout',
            icon: '',
            hidden: false,
            fatherPath: '/',
            keywords: '',
            description: ''
        },

        children: [
            {
                path: "/Home",
                name: "Home",
                component: () => import("@/views/Home/Home.vue"),

                meta: {
                    title: "汇泽保险- 汇集南北 泽润天下",
                    // title: "首页",
                    image: "",
                    hidden: false,

                    fatherPath: "/",
                    keywords: '汇泽保险- 汇集南北 泽润天下',
                    description: '汇泽保险- 汇集南北 泽润天下'
                },
            },
            {
                path: "/Abouthz",
                name: "Abouthz",

                component: () => import("@/views/Abouthz/Abouthz.vue"),

                meta: {
                    title: "关于汇泽",
                    image: "iconfont icon-home",
                    hidden: false,
                    keywords: '',
                    description: ''

                },

            },


            {
                path: "/Products",
                name: "Products",
                component: () => import("@/views/Products/Products.vue"),

                meta: {
                    title: "行业方案",
                    image: "iconfont icon-home",
                    hidden: false,

                    fatherPath: "/",
                    keywords: '',
                    description: ''
                },
            },
            {
                path: "/ListDetail",
                name: "ListDetail",
                component: () => import("@/views/ListDetail/ListDetail.vue"),

                meta: {
                    title: "保险课堂",
                    image: "iconfont icon-home",
                    hidden: false,

                    fatherPath: "/",
                    keywords: '',
                    description: ''
                },
            },

            {
                path: "/InforDisclosure",
                name: "InforDisclosure",
                component: () => import("@/views/InforDisclosure/InforDisclosure.vue"),

                meta: {
                    title: "信息披露",
                    image: "iconfont icon-home",
                    hidden: false,

                    fatherPath: "/",
                    keywords: '',
                    description: ''
                },
            },
            {
                path: "/ContactUs",
                name: "ContactUs",
                component: () => import("@/views/ContactUs/ContactUs.vue"),

                meta: {
                    title: "联系我们",
                    image: "iconfont icon-home",
                    hidden: false,

                    fatherPath: "/",
                    keywords: '',
                    description: ''
                },
            },

            {
                path: "/NewsDetail",
                name: "NewsDetail",
                component: () => import("@/views/NewsDetail/NewsDetail.vue"),

                meta: {
                    title: "详情",
                    image: "iconfont icon-home",
                    hidden: false,

                    fatherPath: "/",
                    keywords: '',
                    description: ''
                },
            },
            {
                path: "/Search",
                name: "Search",
                component: () => import("@/views/Search/Search.vue"),

                meta: {
                    title: "搜索",
                    image: "iconfont icon-home",
                    hidden: false,

                    fatherPath: "/",
                    keywords: '',
                    description: ''
                },
            },
            {
                path: "/Personal",
                name: "Personal",
                component: () => import("@/views/Personal/Personal.vue"),

                meta: {
                    title: "个人中心",
                    image: "iconfont icon-home",
                    hidden: false,

                    fatherPath: "/",
                    keywords: '',
                    description: ''
                },
            },

        ]
    },
    {
        path: "/Login",
        name: "Login",
        component: () => import("@/views/Login/Login.vue"),
        meta: {
            title: "登录",
            image: "iconfont icon-wendang",
            fatherPath: "/",
            keywords: '',
            description: ''
        },
    }
]
export default routes;
