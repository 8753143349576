import { defineStore } from "pinia";
// 定义一个关于topbar的store
const commoninfoStore = defineStore("commoninfo", {

  // defineStore('commoninfo',{})  commoninfo就是这个仓库的名称name
  state: () => ({
    userInfo: localStorage.getItem('userInfo') || {},
    change_path: localStorage.getItem('change_path') || 'pc',
    num: 1,
    baseinfo: localStorage.getItem('baseinfo') || {},
    menuTop: localStorage.getItem('menuTop') || [],
    menuDown: localStorage.getItem('menuDown') || [],
    menuRoutes: {},
    isAuthenticated: false,
    token: localStorage.getItem('token') || '',
    onlinelist: localStorage.getItem('onlinelist') || [],
    phone: localStorage.getItem('phone') || '',
    UserName: localStorage.getItem('UserName') || ''

  }),

  getters: { getNum2: (state) => state.num * 2 },

  // 类似methods
  actions: {
    setUserInfo(info = null) {
      this.userInfo = info || ''
      console.log(info);

    },
    setUserName(name = null) {
      this.UserName = name || ''

    },
    setAuthenticated(flag) {
      this.isAuthenticated = flag;
    },
    setPhone(data) {
      this.phone = data;
    },
    setBaseinfo(data) {
      this.baseinfo = data;
    },
    setmenuTop(data) {
      this.menuTop = data
    },
    setmenuDown(data) {
      this.menuDown = data
    },
    setmenChangepath(data) {
      this.change_path = data
    },
    setmentoken(data) {
      this.token = data
    },
    setonlineData(data) {
      this.onlinelist = data
    }
  },

  persist: {
    storage: localStorage, //   sessionStorage
  },



}



);

export default commoninfoStore;
