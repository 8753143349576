let observer;
function _observer(el, src) {
    observer = new IntersectionObserver(entries => {
        for (let index = 0; index < entries.length; index++) {
            // 获取到目标元素img标签
            const target = entries[index].target;
            // console.log('lazyload-target', target);
            const src = target.getAttribute("data-src");


            // 观察者返回的对象
            const element = entries[index]
            if (element.isIntersecting) {

                target.setAttribute("src", src ?? ""); // 将真实的图片地址赋给 src 属性

                target.onload = () => {
                    // target.setAttribute("class", "M-imgsty");
                    // 添加CSS类名
                    target.classList.add('M-imgsty');
                    // target.setAttribute("class", "helen-fade-in");
                    target.classList.add("class", "helen-fade-in");

                };

                // console.log('显示了', target.src)
                observer && observer.unobserve(target)
            }
        }
    })
    observer.observe(el)
}

export default {
    mounted(el, binding, vnode) {
        _observer(el, binding.value)
    },
    unmounted(el) {
        observer.disconnect()
    }
}