/**axios封装
 * 请求拦截、相应拦截、错误统一处理
 */
import { ref } from "vue";

import axios from "axios";
import QS from "qs";
import { useRouter } from "vue-router";
import router from "../router/index";
import { message } from "ant-design-vue";
// const router = useRouter();
let spinning = ref(false);
let flag = false;
axios.defaults.baseURL = process.env.VUE_APP_BASE_API;
let $axiosBaseUrl = process.env.VUE_APP_AXIOSBASEURL;
// 允许接口携带COOKIE
axios.defaults.withCredentials = true;




axios.interceptors.request.use(
  //   //请求拦截
  async (config) => {
    // console.log("请求拦截config", config);
    let baserouter = router.currentRoute.value.path;
    // console.log("baserouter", baserouter);

    if (baserouter == "/Personal") {
      let commoninfo = JSON.parse(localStorage.getItem('commoninfo')) || ''
      console.log('userinfo', commoninfo.userInfo);
      let token = ''
      if (commoninfo && commoninfo.userInfo) {
        token = commoninfo.userInfo.accessToken || "";

      }
      if (token) {
        config.headers.Authorization = "Bearer" + " " + token;
        console.log("有token");

        return config;
      } else {

        console.log("无token", token);


        if (!flag) {
          message.error("请重新登录", 2);
          flag = true;
        }
        setTimeout(() => {
          router.push("/Login");
        }, 1000);
      }
    } else {
      return config;

    }
  },
  (error) => {
    spinning.value = false;

    console.log("error", error);
    return Promise.error(error);
  }
);

// // 响应拦截器
axios.interceptors.response.use(
  (response) => {
    console.log("response", response);
    if (response.status === 200) {

      console.log("成功response", response);
      return Promise.resolve(response); //进行中
    } else {
      console.log("失败response", response);

      return Promise.reject(response); //失败
    }
  },
  // 服务器状态码不是200的情况
  (error) => {
    spinning.value = false;
    console.log("errorerror", error);
    console.log("error.config.url", error.config.url);


    if (error.response.status) {

      return Promise.reject(error.response);
    }
  }
);
/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
const $get = (url, params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
};
/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
const $post = (url, params) => {
  return new Promise((resolve, reject) => {
    axios
      // .post(url, QS.stringify(params)) //是将对象 序列化成URL的形式，以&进行拼接
      .post(url, params) //是将对象 序列化成URL的形式，以&进行拼接
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
};
const $put = (url, params) => {
  return new Promise((resolve, reject) => {
    axios
      .put(url, params) //是将对象 序列化成URL的形式，以&进行拼接
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
};
const $delete = (url, params) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(url, { data: params }) //是将对象 序列化成URL的形式，以&进行拼接
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
};

//下面是vue3必须加的，vue2不需要，只需要暴露出去get，post方法就可以
export default {
  install: (app) => {
    app.config.globalProperties["$get"] = $get;
    app.config.globalProperties["$post"] = $post;
    app.config.globalProperties["$put"] = $put;
    app.config.globalProperties["$delete"] = $delete;
    app.config.globalProperties["$axiosBaseUrl"] = $axiosBaseUrl;

    app.config.globalProperties["Axios"] = axios;
  },
};
// 1 默认的格式请求体中的数据会以json字符串的形式发送到后端
// 'Content-Type: application/json '
// // 2 请求体中的数据会以普通表单形式（键值对）发送到后端
//   'Content-Type: application/x-www-form-urlencoded'
// // 3 它会将请求体的数据处理为一条消息，以标签为单元，用分隔符分开。既可以上传键值对，也可以上传文件
//   'Content-Type: multipart/form-data'
export { spinning };
