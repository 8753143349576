<template>
  <div id="app">
    <!-- :key="$route.fullPath" -->
    <router-view :key="$route.fullPath"></router-view>
  </div>
</template>
<script setup>
import { useRouter } from "vue-router";
import { onMounted, ref, getCurrentInstance, watchEffect } from "vue";

const { proxy } = getCurrentInstance();
// window.document.title = "汇泽保险服务 - 汇集南北 澤润天下";

onMounted(() => {});
let router = useRouter();
const Refresh = () => {
  console.log("页面大小改变了");
  router.go(0);
};
</script>
<style lang="less">
@import "@/assets/css/contentus.css";
@import "@/assets/css/system.css";
@import "@/assets/css/common.module.less"; // 用于覆盖上面定义的变量

@import "@/assets/css/base";
@import "@/assets/css/animate.css";
#app {
  font-family: "Microsoft YaHei", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
