import {
  Button,
  Layout,
  Image,
  Form,
  Input, spin,
  Select,
  DatePicker,
  Empty, dropdown, InputNumber,
  Table, modal, menu,
  Row, Col, Carousel, FloatButton, Pagination, Skeleton, Space, Divider, Tag
} from "ant-design-vue";

export const antArr = [
  Button, dropdown,
  Layout,
  Image, Form,
  Select, menu,
  Input, modal, InputNumber,
  spin, Empty,
  DatePicker,
  Table, Tag,
  Row, Col, Carousel, FloatButton, Pagination, Skeleton, Space, Divider,

];
