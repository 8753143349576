import { createRouter, createWebHashHistory } from "vue-router";
import Nprogress from "nprogress"; // 进度条
import "nprogress/nprogress.css"; // 引入样式
import bus from "@/assets/js/utils"
import routes from "./routes"
import getbaseinfo from "./getRouter"
import axios from "axios";
axios.defaults.baseURL = process.env.VUE_APP_BASE_API;
import commoninfoStore from "@/store/commonin";


// 如果是移动端，给 .vue 的命名特殊处理，这里多了一级目录 mobile/
export let change_path = navigator.userAgent.match(
  /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
)
  ? "mobile"
  : "pc";
console.log("手机还是pc端path-change_path", change_path);
localStorage.setItem('change_path', change_path)



async function gemenu() {
  const result = await axios.get("/biz/WebSite/SystemDetail");
  console.log("我是router基本信息1111111", result);
  if (result.status == 200 && result.data.code == 200) {

    localStorage.setItem("baseinfo", JSON.stringify(result.data.data));
    commoninfo.setBaseinfo(result.data.data)
    let baseinfo = result.data.data;
    let menuTop = JSON.parse(baseinfo.menuTop)
    let menuDown = JSON.parse(baseinfo.menuDown)
    localStorage.setItem("menuTop", baseinfo.menuTop);
    localStorage.setItem("menuDown", baseinfo.menuDown);
    commoninfo.setmenuTop(menuTop)
    commoninfo.setmenuDown(menuDown)
    menuTop.forEach(item => {
      routes[0].meta.description = item.description
      routes[0].meta.keywords = item.keywords
    })
    console.log('路由中baseinfo', baseinfo);
    console.log('路由中menuTop', menuTop);
    console.log('路由中routes', routes);
  }

}

const router = new createRouter({
  history: createWebHashHistory(),
  routes,
});


let commoninfo = {}


router.beforeEach(async (to, from, next) => {

  window.document.title = to.meta.title || '汇泽保险服务 - 汇集南北 澤润天下'
  Nprogress.start();

  commoninfo = commoninfoStore();
  commoninfo.setmenChangepath(change_path)

  // if (!commoninfo.isAuthenticated) {
  //   await gemenu()
  //   commoninfo.setAuthenticated(true)


  // }
  gemenu()

  next();

})
router.afterEach((to, from) => {
  Nprogress.done();
});
export default router;
